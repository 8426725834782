let request;
let db;
let version = 1;
let dbName = 'signalsdb';
let storeName = 'signals';

export async function initDB() {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: 'id' });
      }
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      resolve(true);
    };

    request.onerror = () => {
      resolve(false);
    };
  });
}

export async function addData(data) {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName, version);
    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.add(data);
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      console.error(error);
      if (error) {
        resolve(error);
      } else {
        resolve('Unknown error');
      }
    };
  });
}

export async function getData() {
  return new Promise((resolve) => {
    request = indexedDB.open(dbName);
    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result);
      };
    };
  });
}

export async function deleteData(id) {
  return new Promise((resolve) => {
    // again open the connection
    request = indexedDB.open(dbName, version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      const res = store.delete(id);

      // add listeners that will resolve the Promise
      res.onsuccess = () => {
        resolve(true);
      };
      res.onerror = () => {
        resolve(false);
      };
    };
  });
}
