import { useState, useEffect } from 'react';
import { addData } from '../db';

function NewSignal({ setView, signalData, setSignalData }) {
  const [signalName, setSignalName] = useState('');
  const [signalList, setSignalList] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  async function addSignal(_, sn) {
    const now = new Date();
    const time = now.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
    const date = now.getMonth() + 1 + '/' + now.getDate() + '/' + now.getFullYear();
    if (!sn) sn = signalName;
    const data = {
      id: now.getTime(),
      signalName: sn,
      latitude,
      longitude,
      date,
      time,
    };
    await addData(data);
    if (!signalList.includes(sn)) {
      const newList = [...signalList, sn];
      setSignalList(newList);
      localStorage.setItem('signalList', JSON.stringify(newList));
    }
    setSignalName('');
    const newSignalData = [...signalData, data];
    setSignalData(newSignalData);
    setView('data');
  }

  function save(position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  }

  useEffect(() => {
    function updateSignalList() {
      const ls = localStorage.getItem('signalList');
      if (ls) {
        setSignalList(JSON.parse(ls).sort());
      }
    }
    function add() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(save);
      } else {
        save({
          coords: {
            latitude: 0,
            longitude: 0,
            accuracy: 0,
          },
        });
      }
    }
    add();
    updateSignalList();
  }, []);

  function clearList() {
    const clr = window.confirm('Are you sure you want to clear your history?');
    if (clr) {
      setSignalList([]);
      localStorage.setItem('signalList', JSON.stringify([]));
    }
  }

  return (
    <div className="centeredWindow">
      <div className="signalWindow">
        <label>
          <input
            type="text"
            value={signalName}
            onChange={(e) => setSignalName(e.target.value)}
            className="form-control"
            list="list"
            placeholder="Signal Name"
          />
        </label>
        <datalist id="list">
          {signalList.map((s) => (
            <option key={s} value={s} />
          ))}
        </datalist>
        <br />
        <b>Latitude :</b>
        {latitude.toFixed(4)}
        &nbsp;
        <b>Longitude :</b>
        {longitude.toFixed(4)}
        <br />
        <br />
        <button onClick={addSignal} className="btn btn-info quickButton">
          + Add
        </button>
        &nbsp;
        <button onClick={() => setView('data')} className="btn btn-light quickButton">
          Cancel
        </button>
        {signalList.length > 0 && (
          <div>
            <hr />
            <b>History</b>
            <br />
            <br />
            {signalList.map((itm, indx) => {
              return (
                <button className="btn btn-secondary quickButton btn-sm" key={indx} onClick={(e) => addSignal(e, itm)}>
                  {itm}
                </button>
              );
            })}
            {signalList.length > 0 && (
              <div>
                <br />
                <button className="btn btn-danger quickButton btn-sm" onClick={clearList}>
                  Clear
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default NewSignal;
