import './App.css';
import { useEffect, useState } from 'react';
import { initDB, getData } from './db';
import NavBar from './components/Nav';
import Help from './components/Help';
import NewSignal from './components/NewSignal';
import { DotLoader } from 'react-spinners';
import DataTable from './components/DataTable';
import Export from './components/Export';

function App() {
  const [dbInitialized, setDbInitialized] = useState(false);
  const [signalData, setSignalData] = useState([]);
  const [view, setView] = useState('data');

  useEffect(() => {
    async function initializeDatabase() {
      await initDB();
      setDbInitialized(true);
      const dta = await getData();
      setSignalData(dta);
      if (!dta || dta.length === 0) {
        setView('help');
      }
    }
    initializeDatabase();
  }, []);

  return (
    <div className="App">
      <NavBar view={view} setView={setView} />
      {!dbInitialized && <DotLoader />}
      {dbInitialized && (
        <div className="Content">
          {view === 'data' && <DataTable signalData={signalData} setSignalData={setSignalData} />}
          {view === 'add' && <NewSignal setView={setView} signalData={signalData} setSignalData={setSignalData} />}
          {view === 'help' && <Help setView={setView} />}
          {view === 'export' && <Export signalData={signalData} setView={setView} />}
        </div>
      )}
    </div>
  );
}

export default App;
