import { BoxArrowDown, PlusCircle, InfoCircle } from 'react-bootstrap-icons';
import logo from '../logo.png';

function NavBar({ view, setView }) {
  return (
    <div>
      <nav className="navbar fixed-top navbar-light bg-light">
        <span className="navbar-brand mb-0 h1">
          &nbsp;&nbsp;
          <img src={logo} alt="SignalsLogo" height="32px" />
          &nbsp;Signals
        </span>
        <div className="addButton">
          <button className="btn btn-secondary" onClick={() => setView('help')} disabled={view === 'help'}>
            &nbsp;&nbsp;
            <InfoCircle />
            &nbsp;&nbsp;
          </button>
          &nbsp;
          <button className="btn btn-secondary" onClick={() => setView('export')} disabled={view === 'export'}>
            &nbsp;&nbsp;
            <BoxArrowDown />
            &nbsp;&nbsp;
          </button>
          &nbsp;
          <button className="btn btn-info addBtn" onClick={() => setView('add')} disabled={view === 'add'}>
            <PlusCircle />
          </button>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
