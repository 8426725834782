import { Map, XCircle, XSquare } from 'react-bootstrap-icons';
import { deleteData } from '../db';
import { useState } from 'react';

function DataTable({ signalData, setSignalData }) {
  const [signalName, setSignalName] = useState('');

  function deleteItem(s) {
    const del = window.confirm(`Are you sure you want to delete this ${s.signalName} record?`);
    if (del) {
      deleteData(s.id);
      const newData = signalData.filter((itm) => itm.id !== s.id);
      setSignalData(newData);
    }
  }

  return (
    <div className="datatable">
      <div className="signalWindow ">
        {!signalData ||
          (signalData.length === 0 && (
            <div className="nosignals">
              <h4>No signals found</h4>
              <hr />
              Click on the + Data button above to add your first signal.
            </div>
          ))}
        {signalData.length > 0 && (
          <div>
            {signalName && (
              <div>
                <button className="btn btn-light alignRight" onClick={() => setSignalName(null)}>
                  <XSquare />
                  &nbsp;Clear Filter
                </button>
                <br />
                <br />
              </div>
            )}
            <table className="table table-bordered table-striped">
              <thead className="thead-dark">
                <tr>
                  <th>Signal</th>
                  <th>Time</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {signalData &&
                  signalData.map((s, i) => {
                    if (signalName && s.signalName !== signalName) return null;
                    return (
                      <tr key={i}>
                        <td>
                          <a href="#setsignal" onClick={() => setSignalName(s.signalName)}>
                            {s.signalName}
                          </a>
                        </td>
                        <td>
                          {s.date}
                          <br />
                          {s.time}
                        </td>
                        <td>
                          <a
                            href={`https://www.google.com/maps/@${s.latitude},${s.longitude},18z`}
                            target="_blank"
                            rel="noreferrer"
                            className="mapLink"
                          >
                            <Map />
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="#delete" className="deleteLink" onClick={() => deleteItem(s)}>
                            <XCircle />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default DataTable;
