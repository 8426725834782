import { PlusCircle } from 'react-bootstrap-icons';

function Help({ setView }) {
  return (
    <div className="centeredWindow signalWindow helpWindow">
      <h3>About Signals</h3>
      Do you believe there are no such things as coincidences? This web application has been created to allow you to freely collect signals.
      A signal may be a reoccuring number, or an event that continues to happen. Whatever the signal you can use this app to collect and
      store metadata including the event, time, and location.
      <br />
      <br />
      All information in this app is stored in a browser database called&nbsp;
      <a href="https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API" target="_blank" rel="noreferrer">
        indexedDB
      </a>
      &nbsp;which is only accessible by you. We deeply care about your privacy, no data is ever transferred to a server so you can feel
      confident storing your signals.
      <br />
      <br />
      Start adding signals today by clicking on the <PlusCircle /> button in the top right corner of the page.
      <br />
      <br />
      <button onClick={() => setView('data')} className="btn btn-info">
        Close
      </button>
    </div>
  );
}

export default Help;
