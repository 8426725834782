import { useState } from 'react';

function Export({ signalData, setView }) {
  const [fName] = useState('signalData_' + new Date().getTime() + '.csv');

  function exportData() {
    let csvData = signalData.map((sd) => {
      return sd.id + ',' + sd.signalName + ',' + sd.date + ',' + sd.time + ',' + sd.latitude + ',' + sd.longitude;
    });
    const csvContent = 'data:text/csv;charset=utf-8,' + csvData.join('\n');
    const encodedUri = encodeURI(csvContent);
    var link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fName);
    link.click();
    setView('data');
  }

  return (
    <div className="centeredWindow">
      <div className="signalWindow">
        <h4>Export Data</h4>
        <table className="table">
          <tbody>
            <tr>
              <td>File Name:</td>
              <td>{fName}</td>
            </tr>
            <tr>
              <td>Records:</td>
              <td>{signalData.length}</td>
            </tr>
          </tbody>
        </table>

        <button onClick={exportData} className="btn btn-info quickButton">
          Download
        </button>
        <button onClick={() => setView('data')} className="btn btn-light quickButton">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default Export;
